import { Table, Tag } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ProjectsTable extends Component {
  state = {
    data: [],
    pagination: {},
    filteredGroupInfo: null,
    filteredTagInfo: null,
    sortField: 'name',
    sortOrder: 'ascend',
    loading: false
  };

  columns = () => [
    {
      title: '',
      dataIndex: 'icon',
      sorter: false,
      render: icon => (
        <img
          alt='img'
          src={icon}
          style={{
            display: 'block',
            width: 'auto',
            height: 'auto',
            maxWidth: '48px',
            maxHeight: '48px',
            borderRadius: '25px'
          }}
          onError={(e) => { e.target.onerror = null; e.target.src = `${window.location.origin}/icon.png` }}
        />
      ),
      width: '64px'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sortOrder: this.state.sortField === 'name' ? this.state.sortOrder : null,
      sorter: true,
      render: name => <b>{name}</b>,
      width: '20%'
    },
    this.props.isAll
      ? {
        title: 'Group',
        dataIndex: 'namespace.name',
        filteredValue: this.state.filteredGroupInfo || null,
        filters: this.props.data
          .map(project => project.namespace.name)
          .filter((obect, index, arr) => arr.indexOf(obect) === index)
          .sort()
          .map(name => ({
            text: name,
            value: name
          })),
        filtered: true,
        sortOrder: this.state.sortField === 'namespace.name' ? this.state.sortOrder : null,
        sorter: true,
        render: group => group,
        width: '150px'
      }
      : {},
    {
      title: 'Tags',
      dataIndex: 'tag_list',
      filteredValue: this.state.filteredTagInfo || null,
      filters: this.props.tags.map(tag => ({ text: tag, value: tag })),
      render: tags => (
        <span>
          {tags
            ? tags.map(tag => (
              <Tag
                color='blue'
                key={tag}
                onClick={e => {
                  e.cancelBubble = true;
                  if (e.stopPropagation) e.stopPropagation();
                  this.setState({
                    data: [...this.props.data].filter(project =>
                      this.filter(project.tag_list, [tag])
                    ),
                    filteredTagInfo: [tag]
                  });
                  this.props.setParams({ ...this.props.params, tag_list: [tag] });
                }}
              >
                {tag}
              </Tag>
            ))
            : null}
        </span>
      ),
      width: '20%'
    },
    {
      title: 'Desc',
      dataIndex: 'description',
      sortOrder: this.state.sortField === 'description' ? this.state.sortOrder : null,
      sorter: true
    }
  ];

  componentDidMount() {
    // this.fetch();
    let stateParams = {
      pagination: { pageSize: 20 }
    };

    if (Object.keys(this.props.params).length === 0) {
      const newData = [...this.props.data];
      this.sort(newData);
      this.setState({
        ...stateParams,
        data: newData
      });
    } else {
      this.setState({
        ...stateParams,
      });
      this.fetch(this.props.params);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };

  fetch = (params = {}) => {
    this.props.setParams(params);
    let newData = [...this.props.data];
    const order = params.sortOrder === 'descend' ? -1 : 1;
    this.sort(newData, params.sortField, order);

    if (params.tag_list && params.tag_list.length > 0) {
      newData = newData.filter(project =>
        this.filter(project.tag_list, params.tag_list)
      );
    }

    if (params['namespace.name'] && params['namespace.name'].length > 0) {
      newData = newData.filter(project => {
        for (let i = 0; i < params['namespace.name'].length; i++) {
          if (
            project.path_with_namespace.split('/')[0] ===
            params['namespace.name'][i]
          ) {
            return true;
          }
        }
        return false;
      });
    }

    this.setState({
      loading: false,
      data: newData,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filteredGroupInfo: params['namespace.name'],
      filteredTagInfo: params.tag_list
    });
  };

  sort(data, sortField = 'name', order = 1) {
    let newA;
    let newB;
    if (sortField.indexOf('.') >= 0) {
      sortField = 'name_with_namespace';
    }
    data.sort((a, b) => {
      newA = a[sortField].toLowerCase();
      newB = b[sortField].toLowerCase();
      if (newA > newB) {
        return order;
      }
      if (newA < newB) {
        return -1 * order;
      }
      return 0;
    });
  }

  filter(projectList, tagList) {
    for (let i = 0; i < projectList.length; i++) {
      for (let j = 0; j < tagList.length; j++) {
        if (projectList[i] === tagList[j]) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    return (
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              return this.props.history.push('/' + record.path_with_namespace);
            }, // click row
            onDoubleClick: event => { }, // double click row
            onContextMenu: event => { } // right button click row
          };
        }}
        columns={this.columns()}
        rowKey={record => record.id}
        dataSource={this.state.data}
        pagination={this.state.pagination}
        loading={this.state.loading}
        onChange={this.handleTableChange}
        size='small'
      />
    );
  }
}

export default withRouter(ProjectsTable);
