import React, { Component } from 'react';
import { Layout } from 'antd';

class MyFooter extends Component {
  render() {
    return (
      <Layout.Footer style={{ textAlign: 'center' }}>
        Jiří Znoj - 2019
      </Layout.Footer>
    );
  }
}

export default MyFooter;
