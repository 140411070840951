export default [
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/10578374/events",
            "issues": "https://gitlab.com/api/v4/projects/10578374/issues",
            "labels": "https://gitlab.com/api/v4/projects/10578374/labels",
            "members": "https://gitlab.com/api/v4/projects/10578374/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10578374/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10578374/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10578374"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10578374/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-01-28T18:07:22.046Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, Python - Scripts for download, update and modified all my gitlab groups and projects and scripts for creating public content from gitlab projects to be presented in my personal web site.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pythongitlabscript.git",
        "id": 10578374,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T22:03:20.796Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "PythonGitLabScript",
        "name_with_namespace": "Znoj-Desktop / PythonGitLabScript",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pythongitlabscript",
        "path_with_namespace": "Znoj-Desktop/pythongitlabscript",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pythongitlabscript/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pythongitlabscript.git",
        "star_count": 0,
        "tag_list": [
            "2019",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/pythongitlabscript",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/10343473/events",
            "issues": "https://gitlab.com/api/v4/projects/10343473/issues",
            "labels": "https://gitlab.com/api/v4/projects/10343473/labels",
            "members": "https://gitlab.com/api/v4/projects/10343473/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10343473/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10343473/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10343473"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10343473/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-01-13T15:29:40.384Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, Python - Python mini programs for setting GUI in python",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/python-gui.git",
        "id": 10343473,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:44:01.188Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Python GUI",
        "name_with_namespace": "Znoj-Desktop / Python GUI",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "python-gui",
        "path_with_namespace": "Znoj-Desktop/python-gui",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/python-gui/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/python-gui.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/python-gui",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/10233968/events",
            "issues": "https://gitlab.com/api/v4/projects/10233968/issues",
            "labels": "https://gitlab.com/api/v4/projects/10233968/labels",
            "members": "https://gitlab.com/api/v4/projects/10233968/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10233968/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10233968/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10233968"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10233968/liferay-logo.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-01-06T21:11:50.608Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, Python, Shell - Scripts for Liferay Portal",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/liferay-scripts.git",
        "id": 10233968,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:20:24.319Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Liferay scripts",
        "name_with_namespace": "Znoj-Desktop / Liferay scripts",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "liferay-scripts",
        "path_with_namespace": "Znoj-Desktop/liferay-scripts",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/liferay-scripts/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/liferay-scripts.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "Python",
            "Shell",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/liferay-scripts",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/10223244/events",
            "issues": "https://gitlab.com/api/v4/projects/10223244/issues",
            "labels": "https://gitlab.com/api/v4/projects/10223244/labels",
            "members": "https://gitlab.com/api/v4/projects/10223244/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10223244/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10223244/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10223244"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10223244/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-01-05T21:19:00.436Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, C - get greatest from areas around coordinates",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/coordinates.git",
        "id": 10223244,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:41:56.452Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Coordinates",
        "name_with_namespace": "Znoj-Desktop / Coordinates",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "coordinates",
        "path_with_namespace": "Znoj-Desktop/coordinates",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/coordinates/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/coordinates.git",
        "star_count": 0,
        "tag_list": [
            "2019",
            "C"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/coordinates",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7944199/events",
            "issues": "https://gitlab.com/api/v4/projects/7944199/issues",
            "labels": "https://gitlab.com/api/v4/projects/7944199/labels",
            "members": "https://gitlab.com/api/v4/projects/7944199/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7944199/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7944199/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7944199"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7944199/blog_error.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-14T21:32:28.981Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, JS - Blog post",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/senchaTest.git",
        "id": 7944199,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-02T18:17:02.921Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Sencha Test",
        "name_with_namespace": "Znoj-Desktop / Sencha Test",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "senchaTest",
        "path_with_namespace": "Znoj-Desktop/senchaTest",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/senchaTest/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/senchaTest.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "JS",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/senchaTest",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7944180/events",
            "issues": "https://gitlab.com/api/v4/projects/7944180/issues",
            "labels": "https://gitlab.com/api/v4/projects/7944180/labels",
            "members": "https://gitlab.com/api/v4/projects/7944180/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7944180/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7944180/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7944180"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7944180/pasted-image-0-9.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-14T21:29:44.820Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, Java + JS - Blog posts",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/liferayPortlet.git",
        "id": 7944180,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-02T18:15:42.129Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Liferay portlet",
        "name_with_namespace": "Znoj-Desktop / Liferay portlet",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "liferayPortlet",
        "path_with_namespace": "Znoj-Desktop/liferayPortlet",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/liferayPortlet/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/liferayPortlet.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "JS",
            "Java",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/liferayPortlet",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786591/events",
            "issues": "https://gitlab.com/api/v4/projects/7786591/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786591/labels",
            "members": "https://gitlab.com/api/v4/projects/7786591/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786591/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786591/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786591"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786591/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:37:54.800Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C++ - Projects for class PR1 (descriptions, resolutions, inputs, outputs)",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pr1.git",
        "id": 7786591,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:54.935Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "PR1",
        "name_with_namespace": "Znoj-Desktop / PR1",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pr1",
        "path_with_namespace": "Znoj-Desktop/pr1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pr1/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pr1.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/pr1",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786507/events",
            "issues": "https://gitlab.com/api/v4/projects/7786507/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786507/labels",
            "members": "https://gitlab.com/api/v4/projects/7786507/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786507/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786507/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786507"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786507/asmIcon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:29:00.030Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, ASM - basics of ASM for a class \"Computer viruses and security of computer systems\"",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/asmTest.git",
        "id": 7786507,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:19:39.999Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "ASM basics",
        "name_with_namespace": "Znoj-Desktop / ASM basics",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "asmTest",
        "path_with_namespace": "Znoj-Desktop/asmTest",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/asmTest/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/asmTest.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "ASM"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/asmTest",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786299/events",
            "issues": "https://gitlab.com/api/v4/projects/7786299/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786299/labels",
            "members": "https://gitlab.com/api/v4/projects/7786299/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786299/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786299/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786299"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786299/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:07:15.121Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/zkousky.git",
        "id": 7786299,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:37.642Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - Zkou\u0161ky",
        "name_with_namespace": "Znoj-Desktop / Django - Zkou\u0161ky",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "zkousky",
        "path_with_namespace": "Znoj-Desktop/zkousky",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/zkousky/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/zkousky.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/zkousky",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786276/events",
            "issues": "https://gitlab.com/api/v4/projects/7786276/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786276/labels",
            "members": "https://gitlab.com/api/v4/projects/7786276/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786276/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786276/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786276"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786276/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:05:17.687Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/zapasy.git",
        "id": 7786276,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:31.807Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - Z\u00e1pasy",
        "name_with_namespace": "Znoj-Desktop / Django - Z\u00e1pasy",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "zapasy",
        "path_with_namespace": "Znoj-Desktop/zapasy",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/zapasy/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/zapasy.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/zapasy",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786238/events",
            "issues": "https://gitlab.com/api/v4/projects/7786238/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786238/labels",
            "members": "https://gitlab.com/api/v4/projects/7786238/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786238/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786238/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786238"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786238/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:02:32.733Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/nemocnice.git",
        "id": 7786238,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:34:34.001Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - Nemocnice",
        "name_with_namespace": "Znoj-Desktop / Django - Nemocnice",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "nemocnice",
        "path_with_namespace": "Znoj-Desktop/nemocnice",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/nemocnice/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/nemocnice.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/nemocnice",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786223/events",
            "issues": "https://gitlab.com/api/v4/projects/7786223/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786223/labels",
            "members": "https://gitlab.com/api/v4/projects/7786223/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786223/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786223/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786223"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786223/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T22:01:01.574Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/ligaMistru.git",
        "id": 7786223,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:33:59.773Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - Liga mistr\u016f",
        "name_with_namespace": "Znoj-Desktop / Django - Liga mistr\u016f",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "ligaMistru",
        "path_with_namespace": "Znoj-Desktop/ligaMistru",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/ligaMistru/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/ligaMistru.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/ligaMistru",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786208/events",
            "issues": "https://gitlab.com/api/v4/projects/7786208/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786208/labels",
            "members": "https://gitlab.com/api/v4/projects/7786208/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786208/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786208/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786208"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786208/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T21:59:41.211Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/lecebna.git",
        "id": 7786208,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:20:18.838Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - L\u00e9\u010debna",
        "name_with_namespace": "Znoj-Desktop / Django - L\u00e9\u010debna",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "lecebna",
        "path_with_namespace": "Znoj-Desktop/lecebna",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/lecebna/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/lecebna.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/lecebna",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7786053/events",
            "issues": "https://gitlab.com/api/v4/projects/7786053/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786053/labels",
            "members": "https://gitlab.com/api/v4/projects/7786053/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786053/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786053/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786053"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786053/django.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T21:44:41.668Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/skladKnih.git",
        "id": 7786053,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:34:58.089Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Django - Sklad knih",
        "name_with_namespace": "Znoj-Desktop / Django - Sklad knih",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "skladKnih",
        "path_with_namespace": "Znoj-Desktop/skladKnih",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/skladKnih/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/skladKnih.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "Python"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/skladKnih",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7785945/events",
            "issues": "https://gitlab.com/api/v4/projects/7785945/issues",
            "labels": "https://gitlab.com/api/v4/projects/7785945/labels",
            "members": "https://gitlab.com/api/v4/projects/7785945/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7785945/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7785945/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7785945"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7785945/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T21:32:41.252Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program pro v\u00fdpo\u010det spot\u0159eby vody, elektriky, plynu",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/spotreba.git",
        "id": 7785945,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:44:20.305Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Spot\u0159eba",
        "name_with_namespace": "Znoj-Desktop / Spot\u0159eba",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "spotreba",
        "path_with_namespace": "Znoj-Desktop/spotreba",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/spotreba/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/spotreba.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/spotreba",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7785922/events",
            "issues": "https://gitlab.com/api/v4/projects/7785922/issues",
            "labels": "https://gitlab.com/api/v4/projects/7785922/labels",
            "members": "https://gitlab.com/api/v4/projects/7785922/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7785922/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7785922/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7785922"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7785922/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-06T21:30:19.853Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed, n\u00e1soben\u00ed, faktori\u00e1l nad velk\u00fdmi \u010d\u00edsly",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/operace_s_cisly.git",
        "id": 7785922,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:43.240Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Operace s velk\u00fdmi \u010d\u00edsly",
        "name_with_namespace": "Znoj-Desktop / Operace s velk\u00fdmi \u010d\u00edsly",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "operace_s_cisly",
        "path_with_namespace": "Znoj-Desktop/operace_s_cisly",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/operace_s_cisly/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/operace_s_cisly.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/operace_s_cisly",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7769429/events",
            "issues": "https://gitlab.com/api/v4/projects/7769429/issues",
            "labels": "https://gitlab.com/api/v4/projects/7769429/labels",
            "members": "https://gitlab.com/api/v4/projects/7769429/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7769429/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7769429/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7769429"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7769429/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-05T19:09:41.273Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed a n\u00e1soben\u00ed nad velk\u00fdmi \u010d\u00edsly",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/velka_cisla.git",
        "id": 7769429,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:44:44.010Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Velk\u00e1 \u010d\u00edsla",
        "name_with_namespace": "Znoj-Desktop / Velk\u00e1 \u010d\u00edsla",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "velka_cisla",
        "path_with_namespace": "Znoj-Desktop/velka_cisla",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/velka_cisla/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/velka_cisla.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/velka_cisla",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7769428/events",
            "issues": "https://gitlab.com/api/v4/projects/7769428/issues",
            "labels": "https://gitlab.com/api/v4/projects/7769428/labels",
            "members": "https://gitlab.com/api/v4/projects/7769428/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7769428/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7769428/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7769428"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7769428/logo.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-05T19:09:15.928Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - V\u00fdpo\u010det logaritmu podle podle vzorce ln(x) + ln(1 + e^(ln(y)-log(x))),",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/logaritmus.git",
        "id": 7769428,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:34:09.358Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Logaritmus",
        "name_with_namespace": "Znoj-Desktop / Logaritmus",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "logaritmus",
        "path_with_namespace": "Znoj-Desktop/logaritmus",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/logaritmus/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/logaritmus.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/logaritmus",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7761640/events",
            "issues": "https://gitlab.com/api/v4/projects/7761640/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761640/labels",
            "members": "https://gitlab.com/api/v4/projects/7761640/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761640/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761640/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761640"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761640/maze_icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T22:46:56.074Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C++ - Rekurzivn\u00ed, nerekurzivn\u00ed, paraleln\u00ed algoritmy a jejich srovn\u00e1n\u00ed",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/maze.git",
        "id": 7761640,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:34:15.305Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Maze - Hled\u00e1n\u00ed cykl\u016f v bludi\u0161ti",
        "name_with_namespace": "Znoj-Desktop / Maze - Hled\u00e1n\u00ed cykl\u016f v bludi\u0161ti",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "maze",
        "path_with_namespace": "Znoj-Desktop/maze",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/maze/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/maze.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/maze",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7761531/events",
            "issues": "https://gitlab.com/api/v4/projects/7761531/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761531/labels",
            "members": "https://gitlab.com/api/v4/projects/7761531/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761531/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761531/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761531"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761531/catalanIcon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T22:33:55.368Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C - Recursive and non-recursive algorithm for counting Catalan number",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/catalan.git",
        "id": 7761531,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:19:46.194Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Catalan number algs",
        "name_with_namespace": "Znoj-Desktop / Catalan number algs",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "catalan",
        "path_with_namespace": "Znoj-Desktop/catalan",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/catalan/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/catalan.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "C"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/catalan",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7761484/events",
            "issues": "https://gitlab.com/api/v4/projects/7761484/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761484/labels",
            "members": "https://gitlab.com/api/v4/projects/7761484/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761484/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761484/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761484"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761484/IS_KNIHOVNA_zakaznik.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T22:22:42.784Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C# - Vytvo\u0159en\u00ed IS knihovny, kde lze proch\u00e1zet a vyhled\u00e1vat knihy, kter\u00e9 knihovna nab\u00edz\u00ed, zobrazovat detaily o autorovi v\u010detn\u011b fotky, \u017eivotopisu a knih, kter\u00e9 se od n\u011bj v knihovn\u011b nach\u00e1z\u00ed...",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/knihovna_IS.git",
        "id": 7761484,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:20:08.321Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "IS knihovna",
        "name_with_namespace": "Znoj-Desktop / IS knihovna",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "knihovna_IS",
        "path_with_namespace": "Znoj-Desktop/knihovna_IS",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/knihovna_IS/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/knihovna_IS.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/knihovna_IS",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7760403/events",
            "issues": "https://gitlab.com/api/v4/projects/7760403/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760403/labels",
            "members": "https://gitlab.com/api/v4/projects/7760403/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760403/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760403/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760403"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760403/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T19:28:23.374Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Network - Network design",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pos.git",
        "id": 7760403,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:48.951Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "pos",
        "name_with_namespace": "Znoj-Desktop / pos",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pos",
        "path_with_namespace": "Znoj-Desktop/pos",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pos/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pos.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "Network"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/pos",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7760309/events",
            "issues": "https://gitlab.com/api/v4/projects/7760309/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760309/labels",
            "members": "https://gitlab.com/api/v4/projects/7760309/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760309/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760309/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760309"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760309/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T19:10:43.273Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C#, ASP.NET - Implementace \u010d\u00e1sti IS navr\u017een\u00e9ho v [MHD - n\u00e1vrh IS a SQL skripty](https://gitlab.com/Znoj-Desktop/MHD)",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/MHD_IS.git",
        "id": 7760309,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:15.404Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "MHD - implementace \u010d\u00e1sti navr\u017een\u00e9ho IS",
        "name_with_namespace": "Znoj-Desktop / MHD - implementace \u010d\u00e1sti navr\u017een\u00e9ho IS",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "MHD_IS",
        "path_with_namespace": "Znoj-Desktop/MHD_IS",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/MHD_IS/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/MHD_IS.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "ASP.NET",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/MHD_IS",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7760284/events",
            "issues": "https://gitlab.com/api/v4/projects/7760284/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760284/labels",
            "members": "https://gitlab.com/api/v4/projects/7760284/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760284/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760284/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760284"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760284/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T19:06:39.070Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, SQL - N\u00e1vrh IS - Specifikace zad\u00e1n\u00ed, Datov\u00e1 anal\u00fdza, funk\u010dn\u00ed z\u00e1vislosti a norm\u00e1ln\u00ed formy + SQL skripty",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/MHD.git",
        "id": 7760284,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:09.063Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "MHD - n\u00e1vrh IS a SQL skripty",
        "name_with_namespace": "Znoj-Desktop / MHD - n\u00e1vrh IS a SQL skripty",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "MHD",
        "path_with_namespace": "Znoj-Desktop/MHD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/MHD/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/MHD.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "SQL"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/MHD",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7760202/events",
            "issues": "https://gitlab.com/api/v4/projects/7760202/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760202/labels",
            "members": "https://gitlab.com/api/v4/projects/7760202/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760202/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760202/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760202"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760202/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T18:56:07.543Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program provede v\u00fdpo\u010det druh\u00e9 odmocniny. \u010c\u00edslo, z kter\u00e9ho se bude odmocnina vypo\u010d\u00edt\u00e1vat m\u016f\u017ee obsahovat velk\u00e9 mno\u017estv\u00ed cifer (i nad 10 tis\u00edc).",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/odmocnina.git",
        "id": 7760202,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:37.430Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Odmocnina",
        "name_with_namespace": "Znoj-Desktop / Odmocnina",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "odmocnina",
        "path_with_namespace": "Znoj-Desktop/odmocnina",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/odmocnina/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/odmocnina.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/odmocnina",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7760174/events",
            "issues": "https://gitlab.com/api/v4/projects/7760174/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760174/labels",
            "members": "https://gitlab.com/api/v4/projects/7760174/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760174/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760174/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760174"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760174/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T18:50:51.469Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++ - Konkordance - listing all words existing in given text with numbers representing line(s) where particular word exists",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/konkordance.git",
        "id": 7760174,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:42:33.398Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Konkordance",
        "name_with_namespace": "Znoj-Desktop / Konkordance",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "konkordance",
        "path_with_namespace": "Znoj-Desktop/konkordance",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/konkordance/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/konkordance.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/konkordance",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7759369/events",
            "issues": "https://gitlab.com/api/v4/projects/7759369/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759369/labels",
            "members": "https://gitlab.com/api/v4/projects/7759369/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759369/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759369/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759369"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759369/R-tree_S.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T16:58:37.142Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C++ - Pr\u00e1ce se zab\u00fdv\u00e1 stromov\u00fdmi datov\u00fdmi strukturami. P\u0159edev\u0161\u00edm B+ stromy a R\u2013stromy,",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/n-grams.git",
        "id": 7759369,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:34:28.497Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Bakal\u00e1\u0159sk\u00e1 pr\u00e1ce N-grams",
        "name_with_namespace": "Znoj-Desktop / Bakal\u00e1\u0159sk\u00e1 pr\u00e1ce N-grams",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "n-grams",
        "path_with_namespace": "Znoj-Desktop/n-grams",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/n-grams/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/n-grams.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/n-grams",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7759254/events",
            "issues": "https://gitlab.com/api/v4/projects/7759254/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759254/labels",
            "members": "https://gitlab.com/api/v4/projects/7759254/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759254/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759254/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759254"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759254/tacr.jpeg",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T16:42:32.656Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C#, ASP.NET - Implementign WebAPI and Notification for TACR (Technology Agency of the Czech\r\nRepublic) project during 816 working hours.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/tacr.git",
        "id": 7759254,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:18.188Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "TACR",
        "name_with_namespace": "Znoj-Desktop / TACR",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "tacr",
        "path_with_namespace": "Znoj-Desktop/tacr",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/tacr/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/tacr.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "ASP.NET",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/tacr",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7759168/events",
            "issues": "https://gitlab.com/api/v4/projects/7759168/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759168/labels",
            "members": "https://gitlab.com/api/v4/projects/7759168/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759168/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759168/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759168"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759168/goodwareDownloadScript.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T16:30:45.047Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C# - Script for getting non-malware software from publicly accessible sources like Studna, SourceForge or FileHippo",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/downloadScript.git",
        "id": 7759168,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:19:56.702Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Download script",
        "name_with_namespace": "Znoj-Desktop / Download script",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "downloadScript",
        "path_with_namespace": "Znoj-Desktop/downloadScript",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/downloadScript/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/downloadScript.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/downloadScript",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757328/events",
            "issues": "https://gitlab.com/api/v4/projects/7757328/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757328/labels",
            "members": "https://gitlab.com/api/v4/projects/7757328/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757328/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757328/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757328"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757328/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:49:37.096Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++, Java - P\u0159eklada\u010d textu z a do morseovky",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/morseovka.git",
        "id": 7757328,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:43:21.398Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Morseovka",
        "name_with_namespace": "Znoj-Desktop / Morseovka",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "morseovka",
        "path_with_namespace": "Znoj-Desktop/morseovka",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/morseovka/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/morseovka.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "C++",
            "Java"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/morseovka",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757221/events",
            "issues": "https://gitlab.com/api/v4/projects/7757221/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757221/labels",
            "members": "https://gitlab.com/api/v4/projects/7757221/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757221/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757221/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757221"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757221/onlinejudgelogo.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:31:20.042Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C++ - 14 programs from school subject \"Programming seminar\" \r\nTasks are from https://uva.onlinejudge.org",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/spr.git",
        "id": 7757221,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T23:40:10.149Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "SPR - Programming seminar - uva.onlinejudge.org",
        "name_with_namespace": "Znoj-Desktop / SPR - Programming seminar - uva.onlinejudge.org",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "spr",
        "path_with_namespace": "Znoj-Desktop/spr",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/spr/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/spr.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/spr",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757209/events",
            "issues": "https://gitlab.com/api/v4/projects/7757209/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757209/labels",
            "members": "https://gitlab.com/api/v4/projects/7757209/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757209/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757209/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757209"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757209/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:29:17.024Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++ - SMS cutter",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/smsCutter.git",
        "id": 7757209,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:44:13.633Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "smsCutter",
        "name_with_namespace": "Znoj-Desktop / smsCutter",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "smsCutter",
        "path_with_namespace": "Znoj-Desktop/smsCutter",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/smsCutter/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/smsCutter.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/smsCutter",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757156/events",
            "issues": "https://gitlab.com/api/v4/projects/7757156/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757156/labels",
            "members": "https://gitlab.com/api/v4/projects/7757156/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757156/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757156/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757156"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757156/Qt_logo_2016.svg.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:22:32.530Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C++ - GUI in QT - Cars and Employees, Options, Vehicles Database ",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/uro.git",
        "id": 7757156,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:23.799Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "QT GUI projects",
        "name_with_namespace": "Znoj-Desktop / QT GUI projects",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "uro",
        "path_with_namespace": "Znoj-Desktop/uro",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/uro/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/uro.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/uro",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757119/events",
            "issues": "https://gitlab.com/api/v4/projects/7757119/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757119/labels",
            "members": "https://gitlab.com/api/v4/projects/7757119/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757119/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757119/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757119"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757119/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:17:40.310Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - Brute force md5 hash cracker (paralel and non paralel algorithm)",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/hash.git",
        "id": 7757119,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:42:14.714Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Brute force md5 hash cracker",
        "name_with_namespace": "Znoj-Desktop / Brute force md5 hash cracker",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "hash",
        "path_with_namespace": "Znoj-Desktop/hash",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/hash/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/hash.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/hash",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757100/events",
            "issues": "https://gitlab.com/api/v4/projects/7757100/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757100/labels",
            "members": "https://gitlab.com/api/v4/projects/7757100/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757100/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757100/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757100"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757100/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:15:48.603Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - Simple DOS attack",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/dosAttack.git",
        "id": 7757100,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:42:02.635Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Simple DOS attack",
        "name_with_namespace": "Znoj-Desktop / Simple DOS attack",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "dosAttack",
        "path_with_namespace": "Znoj-Desktop/dosAttack",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/dosAttack/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/dosAttack.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/dosAttack",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757042/events",
            "issues": "https://gitlab.com/api/v4/projects/7757042/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757042/labels",
            "members": "https://gitlab.com/api/v4/projects/7757042/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757042/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757042/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757042"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757042/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:05:28.057Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C++ - My text compression algorithm",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/KOD.git",
        "id": 7757042,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-20T22:42:27.338Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "KOD",
        "name_with_namespace": "Znoj-Desktop / KOD",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "KOD",
        "path_with_namespace": "Znoj-Desktop/KOD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/KOD/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/KOD.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/KOD",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757014/events",
            "issues": "https://gitlab.com/api/v4/projects/7757014/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757014/labels",
            "members": "https://gitlab.com/api/v4/projects/7757014/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757014/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757014/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757014"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757014/screen.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:59:59.188Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - program for Bc. Jan Gronych's bachelor thesis to track AED's in Olomouc region",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/AED_bakalarka.git",
        "id": 7757014,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:18:26.470Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "AED_bakalarka",
        "name_with_namespace": "Znoj-Desktop / AED_bakalarka",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "AED_bakalarka",
        "path_with_namespace": "Znoj-Desktop/AED_bakalarka",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/AED_bakalarka/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/AED_bakalarka.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "C#"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/AED_bakalarka",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737226/events",
            "issues": "https://gitlab.com/api/v4/projects/7737226/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737226/labels",
            "members": "https://gitlab.com/api/v4/projects/7737226/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737226/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737226/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737226"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737226/library.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:11:51.767Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C++- library for reading libraries from PE files based on \"PE Maker\" from Ashkbiz Danehkar.  \r\nFrom me:\r\n- 64-bit files support\r\n- upgraded to use MSVC2017 64b\r\n- updated API\r\n- bug fixes\r\n- stability fixes, exceptions handling\r\n- fixed memory leaks",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/antivirusHeader.git",
        "id": 7737226,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T16:19:32.899Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Extract info from PE headers",
        "name_with_namespace": "Znoj-Desktop / Extract info from PE headers",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "antivirusHeader",
        "path_with_namespace": "Znoj-Desktop/antivirusHeader",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/antivirusHeader/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/antivirusHeader.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "C++"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Desktop/antivirusHeader",
        "wiki_enabled": true
    }
]