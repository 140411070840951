import { default as ZnojDesktop } from './Znoj-Desktop.js';
import { default as ZnojMobile } from './Znoj-Mobile.js';
import { default as ZnojWEB } from './Znoj-WEB.js';

export const GitlabGroups = [
    {
        "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
        "description": "",
        "full_name": "Znoj-Desktop",
        "full_path": "Znoj-Desktop",
        "id": 3335002,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "name": "Znoj-Desktop",
        "parent_id": null,
        "path": "Znoj-Desktop",
        "request_access_enabled": false,
        "visibility": "private",
        "web_url": "https://gitlab.com/groups/Znoj-Desktop"
    },
    {
        "avatar_url": "https://assets.gitlab-static.net/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
        "description": "",
        "full_name": "Znoj-Mobile",
        "full_path": "Znoj-Mobile",
        "id": 3335000,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "name": "Znoj-Mobile",
        "parent_id": null,
        "path": "Znoj-Mobile",
        "request_access_enabled": true,
        "visibility": "public",
        "web_url": "https://gitlab.com/groups/Znoj-Mobile"
    },
    {
        "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
        "description": "",
        "full_name": "Znoj-WEB",
        "full_path": "Znoj-WEB",
        "id": 3328580,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "name": "Znoj-WEB",
        "parent_id": null,
        "path": "Znoj-WEB",
        "request_access_enabled": false,
        "visibility": "private",
        "web_url": "https://gitlab.com/groups/Znoj-WEB"
    }
]

export default {
  GitlabGroups,
  ZnojDesktop,
  ZnojMobile,
  ZnojWEB
};