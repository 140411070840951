import { Breadcrumb, Layout } from 'antd';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppMenu from '../AppMenu';
import { default as config } from '../configs/groups';
import MyFooter from '../MyFooter';
import ProjectPage from './ProjectPage';
import ProjectsTable from './ProjectsTable';

const { Header, Content } = Layout;

class Group extends Component {
  state = {
    params: {},
  };
  render() {
    let originalData = [];
    let group = '';
    let groupIndex = null;
    let project = '';
    let tags = [];
    let isAll = false;

    if (this.props.location) {
      if (this.props.location.pathname.split('/').length <= 0) {
        return null;
      }
      const pathArray = this.props.location.pathname.split('/');
      if (pathArray && pathArray.length > 1) {
        group = pathArray[1];
        groupIndex = config.GitlabGroups.indexOf(
          config.GitlabGroups.filter(g => g.full_path === group)[0]
        ).toString();
        if (group.toLowerCase() === 'all') {
          isAll = true;
          groupIndex = config.GitlabGroups.length.toString();
        }
        if (pathArray.length > 2) {
          project = pathArray[2];
        }
      } else {
        return null;
      }
    }

    let projects = config[group.replace('-', '')];
    if (isAll) {
      projects = [];
      Object.keys(config)
        .filter(group => group !== 'GitlabGroups')
        .forEach(group => {
          projects = [...projects, ...config[group]];
        });
    }
    if (!!projects) {
      originalData = Object.keys(projects).map(key => {
        return {
          ...projects[key],
          icon: `${window.location.origin}/${
            projects[key].path_with_namespace
            }/README/icon.png`
        };
      });

      Object.keys(projects).map(
        key =>
          (tags = tags.concat(
            ...projects[key].tag_list.filter(tag => tags.indexOf(tag) < 0)
          ))
      );
      tags = tags.sort();
    }
    const projectObject = projects.filter(p => p.path === project)[0]
    const projectName = !!projectObject ? projectObject.name : '';

    return (
      <Layout className='mainLayout'>
        <AppMenu group={group} project={project} groupIndex={groupIndex} />
        <Layout className='layout'>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Breadcrumb style={{ margin: '16px' }}>
              <Breadcrumb.Item>
                <Link to={'/'}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/' + (isAll ? 'all' : this.props.groupName)}>
                  Group {this.props.groupName}
                </Link>
              </Breadcrumb.Item>
              {project ? (
                <Breadcrumb.Item>Project {projectName}</Breadcrumb.Item>
              ) : (
                  ''
                )}
            </Breadcrumb>
          </Header>
          <Content
            style={project ? { margin: '0px 16px' } : { margin: '24px 16px 0' }}
          >
            <div style={{ background: '#fff', minHeight: '100%' }}>
              {project ? (
                <ProjectPage
                  tags={tags}
                  data={originalData}
                  group={group}
                  project={project}
                  projectName={projectName}
                  isAll={isAll}
                />
              ) : (
                  <ProjectsTable
                    tags={tags}
                    data={originalData}
                    group={group}
                    project={project}
                    isAll={isAll}
                    params={this.state.params}
                    setParams={p => {
                      return this.setState({ params: p });
                    }}
                  />
                )}
            </div>
          </Content>
          <MyFooter />
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Group);
