import { Card, Layout } from 'antd';
import React, { Component } from 'react';
import AppMenu from './AppMenu';
import MyFooter from './MyFooter';

const { Content } = Layout;

class Home extends Component {
  render() {
    return (
      <Layout className='mainLayout'>
        <AppMenu />
        <Layout className='layout'>
          <Content style={{ margin: '24px 16px 0' }}>
            <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 600,
                textAlign: 'center'
              }}
            >
              <h1>My GitLab projects</h1>
              <h2>early beta version</h2>
              <Card
                hoverable
                style={{ width: 300, margin: '0 auto' }}
                cover={
                  <img alt='icon' src={`${window.location.origin}/logo.png`} />
                }
              />
            </div>
          </Content>
          <MyFooter />
        </Layout>
      </Layout>
    );
  }
}

export default Home;
