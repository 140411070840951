import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { GitlabGroups } from './configs/groups';
import Group from './Group/Group';
import Home from './Home';

class MyRouter extends Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <Route exact path='/' component={Home} />
          {Object.keys(GitlabGroups).map(key => (
            <Route
              key={key}
              path={'/' + GitlabGroups[key].full_path}
              render={() => <Group groupName={GitlabGroups[key].full_name} />}
            />
          ))}
          <Route
            key='all'
            path={'/all'}
            render={() => <Group groupName={'All groups'} />}
          />
        </React.Fragment>
      </Router>
    );
  }
}

export default MyRouter;
