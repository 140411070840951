import { Card, Spin } from 'antd';
import React, { Component } from 'react';
import * as ReactMarkdown from 'react-markdown';

class ProjectPage extends Component {
  state = {
    markdown: '',
    tags: [],
    group: '',
    project: '',
    isAll: false,
    loading: true
  };

  componentDidMount() {
    let loading = true;
    try {
      const readmePath = require(`../../public/${this.props.group}/${
        this.props.project
        }/README.md`);
      fetch(readmePath)
        .then(response => {
          return response.text();
        })
        .then(text => {
          const textWithFixedPaths = text.replace(
            /\.?\/?README\//g,
            `${window.location.origin}/${this.props.group}/${
            this.props.project
            }/README/`
          );
          this.setState({
            markdown: textWithFixedPaths,
            loading: false
          });
        });
    } catch (e) {
      loading = false;
    }
    this.setState({
      markdown: '',
      loading: loading,
      tags: this.props.tags,
      group: this.props.group,
      project: this.props.project,
      isAll: this.props.isAll
    });
  }

  imageResize = props => (
    <p align='center'>
      <img {...props} alt='img' style={{ maxWidth: '100%' }} />
    </p>
  );

  render() {
    const { loading, markdown } = this.state;

    if (loading) {
      return <Spin tip='Loading...' />;
    }

    return (
      <div style={{ background: '#f0f2f5' }}>
        <Card
          title={this.props.projectName}
          style={{ maxWidth: '1024px', margin: '0 auto' }}
        >
          <ReactMarkdown
            source={markdown}
            escapeHtml={false}
            renderers={{ image: this.imageResize, paragraph: 'span' }}
          />
        </Card>
      </div>
    );
  }
}

export default ProjectPage;
