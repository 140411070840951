export default [
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/8874774/events",
            "issues": "https://gitlab.com/api/v4/projects/8874774/issues",
            "labels": "https://gitlab.com/api/v4/projects/8874774/labels",
            "members": "https://gitlab.com/api/v4/projects/8874774/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/8874774/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/8874774/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/8874774"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/8874774/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-10-15T15:37:21.717Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, Android - Build, configuration, improvement PJSIP library in android for custom implementation of VoIP communication",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/pjproject-2.8.git",
        "id": 8874774,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:36:11.424Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "PJSIP for custom VoIP",
        "name_with_namespace": "Znoj-Mobile / PJSIP for custom VoIP",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pjproject-2.8",
        "path_with_namespace": "Znoj-Mobile/pjproject-2.8",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/pjproject-2.8/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/pjproject-2.8.git",
        "star_count": 0,
        "tag_list": [
            "2018",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/pjproject-2.8",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7761440/events",
            "issues": "https://gitlab.com/api/v4/projects/7761440/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761440/labels",
            "members": "https://gitlab.com/api/v4/projects/7761440/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761440/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761440/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761440"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761440/hero.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T22:17:30.363Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Android - Android game sokoban created as a school project",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/sokoban.git",
        "id": 7761440,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:36:28.786Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "sokoban",
        "name_with_namespace": "Znoj-Mobile / sokoban",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "sokoban",
        "path_with_namespace": "Znoj-Mobile/sokoban",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/sokoban/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/sokoban.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/sokoban",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757260/events",
            "issues": "https://gitlab.com/api/v4/projects/7757260/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757260/labels",
            "members": "https://gitlab.com/api/v4/projects/7757260/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757260/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757260/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757260"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757260/ic_launcher.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:37:09.529Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Concept of an app for VKH Ostrava displaying the same content as the web page.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/vkhApp.git",
        "id": 7757260,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:36:34.262Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "vkhApp",
        "name_with_namespace": "Znoj-Mobile / vkhApp",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "vkhApp",
        "path_with_namespace": "Znoj-Mobile/vkhApp",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/vkhApp/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/vkhApp.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/vkhApp",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757174/events",
            "issues": "https://gitlab.com/api/v4/projects/7757174/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757174/labels",
            "members": "https://gitlab.com/api/v4/projects/7757174/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757174/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757174/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757174"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757174/ic_launcher.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:25:23.629Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Android game created for my girlfriend. It was part of a puzzle and after each level there was clue - key to specific part of the puzzle.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/sestMesicu.git",
        "id": 7757174,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:36:23.284Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "sestMesicu",
        "name_with_namespace": "Znoj-Mobile / sestMesicu",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "sestMesicu",
        "path_with_namespace": "Znoj-Mobile/sestMesicu",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/sestMesicu/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/sestMesicu.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/sestMesicu",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7757064/events",
            "issues": "https://gitlab.com/api/v4/projects/7757064/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757064/labels",
            "members": "https://gitlab.com/api/v4/projects/7757064/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757064/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757064/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757064"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757064/pt.jpg",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T11:11:05.798Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - App listing partitions with used and free space in android devices",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/partitions.git",
        "id": 7757064,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-29T20:35:09.617Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "partitions",
        "name_with_namespace": "Znoj-Mobile / partitions",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "partitions",
        "path_with_namespace": "Znoj-Mobile/partitions",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/partitions/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/partitions.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/partitions",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7756952/events",
            "issues": "https://gitlab.com/api/v4/projects/7756952/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756952/labels",
            "members": "https://gitlab.com/api/v4/projects/7756952/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756952/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756952/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756952"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756952/DjBox.jpg",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:50:37.402Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Concept of android app for fictive company DJ in the Box.\r\nMore about whole project could be found in folder *docs*.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/CDD.git",
        "id": 7756952,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:36:01.553Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "DJ in the Box - CDD",
        "name_with_namespace": "Znoj-Mobile / DJ in the Box - CDD",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "CDD",
        "path_with_namespace": "Znoj-Mobile/CDD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/CDD/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/CDD.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/CDD",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7756938/events",
            "issues": "https://gitlab.com/api/v4/projects/7756938/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756938/labels",
            "members": "https://gitlab.com/api/v4/projects/7756938/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756938/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756938/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756938"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756938/ic_launcher.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:49:10.580Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - App for counting difference between 2 dates - one is current, one is in the future.  \r\nOutput is in various time units.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/cas.git",
        "id": 7756938,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:55.001Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "cas",
        "name_with_namespace": "Znoj-Mobile / cas",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "cas",
        "path_with_namespace": "Znoj-Mobile/cas",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/cas/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/cas.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/cas",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7756899/events",
            "issues": "https://gitlab.com/api/v4/projects/7756899/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756899/labels",
            "members": "https://gitlab.com/api/v4/projects/7756899/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756899/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756899/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756899"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756899/bf.jpg",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:44:30.443Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android NDK - Android app implemented to break md5 hashes using brute force attack implemented in C++",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/bruteForce.git",
        "id": 7756899,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:49.412Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "bruteForce",
        "name_with_namespace": "Znoj-Mobile / bruteForce",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "bruteForce",
        "path_with_namespace": "Znoj-Mobile/bruteForce",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/bruteForce/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/bruteForce.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android NDK"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/bruteForce",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7756887/events",
            "issues": "https://gitlab.com/api/v4/projects/7756887/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756887/labels",
            "members": "https://gitlab.com/api/v4/projects/7756887/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756887/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756887/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756887"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756887/ic_launcher.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:42:42.084Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Android app running on background, periodically checks battery stats and send it to local server. Interval is configurable.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/batteryStats.git",
        "id": 7756887,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-28T17:35:43.196Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "batteryStats",
        "name_with_namespace": "Znoj-Mobile / batteryStats",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "batteryStats",
        "path_with_namespace": "Znoj-Mobile/batteryStats",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/batteryStats/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/batteryStats.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "Android",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/batteryStats",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7756673/events",
            "issues": "https://gitlab.com/api/v4/projects/7756673/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756673/labels",
            "members": "https://gitlab.com/api/v4/projects/7756673/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756673/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756673/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756673"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756673/profiqLogo.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T10:11:51.914Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, Android - demonstration using alternative SDK (openid - oauth2) for Dynepic playPORTAL. It was before they released theirs SDK for communication",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic.git",
        "id": 7756673,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-01-29T20:33:00.806Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "HelloWorldDynepic",
        "name_with_namespace": "Znoj-Mobile / HelloWorldDynepic",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "HelloWorldDynepic",
        "path_with_namespace": "Znoj-Mobile/HelloWorldDynepic",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/HelloWorldDynepic.git",
        "star_count": 0,
        "tag_list": [
            "2018",
            "Android",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic",
        "wiki_enabled": true
    }
]