export default [
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/12295097/events",
            "issues": "https://gitlab.com/api/v4/projects/12295097/issues",
            "labels": "https://gitlab.com/api/v4/projects/12295097/labels",
            "members": "https://gitlab.com/api/v4/projects/12295097/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/12295097/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/12295097/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/12295097"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/12295097/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-05-12T21:14:24.224Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript, php - Balance from CoinMate count in CZK",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/btc-check.git",
        "id": 12295097,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-05-30T16:54:24.586Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "BTC check",
        "name_with_namespace": "Znoj-WEB / BTC check",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "btc-check",
        "path_with_namespace": "Znoj-WEB/btc-check",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/btc-check/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/btc-check.git",
        "star_count": 0,
        "tag_list": [
            "2019",
            "JavaScript",
            "php"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/btc-check",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/10354964/events",
            "issues": "https://gitlab.com/api/v4/projects/10354964/issues",
            "labels": "https://gitlab.com/api/v4/projects/10354964/labels",
            "members": "https://gitlab.com/api/v4/projects/10354964/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10354964/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10354964/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10354964"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10354964/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2019-01-14T10:59:55.303Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript - ",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/gitlabprojects.git",
        "id": 10354964,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-05-30T17:49:10.630Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Gitlab Projects",
        "name_with_namespace": "Znoj-WEB / Gitlab Projects",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "gitlabprojects",
        "path_with_namespace": "Znoj-WEB/gitlabprojects",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/gitlabprojects/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/gitlabprojects.git",
        "star_count": 1,
        "tag_list": [
            "2019",
            "JavaScript"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/gitlabprojects",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7761697/events",
            "issues": "https://gitlab.com/api/v4/projects/7761697/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761697/labels",
            "members": "https://gitlab.com/api/v4/projects/7761697/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761697/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761697/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761697"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761697/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T23:01:09.993Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, ASP.NET - ",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA.git",
        "id": 7761697,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-07T12:07:34.518Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "knihovna_IS_WA",
        "name_with_namespace": "Znoj-WEB / knihovna_IS_WA",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "knihovna_IS_WA",
        "path_with_namespace": "Znoj-WEB/knihovna_IS_WA",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/knihovna_IS_WA.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "ASP.NET"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7759489/events",
            "issues": "https://gitlab.com/api/v4/projects/7759489/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759489/labels",
            "members": "https://gitlab.com/api/v4/projects/7759489/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759489/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759489/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759489"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759489/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-04T17:14:55.248Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, HTML, WordPress - Official web page of action Kol\u0161tejnsk\u00fd okruh created with Dominik Ther",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/kolstejn.git",
        "id": 7759489,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:10.088Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Kol\u0161tejn",
        "name_with_namespace": "Znoj-WEB / Kol\u0161tejn",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "kolstejn",
        "path_with_namespace": "Znoj-WEB/kolstejn",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/kolstejn/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/kolstejn.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "HTML",
            "WordPress"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/kolstejn",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738885/events",
            "issues": "https://gitlab.com/api/v4/projects/7738885/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738885/labels",
            "members": "https://gitlab.com/api/v4/projects/7738885/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738885/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738885/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738885"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738885/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:39:17.287Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP - My personal web page mainly for school purposes",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/znojCz.git",
        "id": 7738885,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:34.848Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "znoj.cz",
        "name_with_namespace": "Znoj-WEB / znoj.cz",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "znojCz",
        "path_with_namespace": "Znoj-WEB/znojCz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/znojCz/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/znojCz.git",
        "star_count": 0,
        "tag_list": [
            "2016",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/znojCz",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738869/events",
            "issues": "https://gitlab.com/api/v4/projects/7738869/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738869/labels",
            "members": "https://gitlab.com/api/v4/projects/7738869/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738869/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738869/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738869"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738869/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:37:22.351Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP - Bookmarks for my favourite shows",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/sim.git",
        "id": 7738869,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:07.982Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Z\u00e1lo\u017eky pro seri\u00e1ly",
        "name_with_namespace": "Znoj-WEB / Z\u00e1lo\u017eky pro seri\u00e1ly",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "sim",
        "path_with_namespace": "Znoj-WEB/sim",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/sim/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/sim.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/sim",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738851/events",
            "issues": "https://gitlab.com/api/v4/projects/7738851/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738851/labels",
            "members": "https://gitlab.com/api/v4/projects/7738851/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738851/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738851/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738851"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738851/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:34:07.764Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP - Web for school subject Computer viruses and security of computer systems",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pvbps.git",
        "id": 7738851,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:01.334Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Po\u010d\u00edta\u010dov\u00e9 viry a bezpe\u010dnost po\u010d\u00edta\u010dov\u00fdch syst\u00e9m\u016f",
        "name_with_namespace": "Znoj-WEB / Po\u010d\u00edta\u010dov\u00e9 viry a bezpe\u010dnost po\u010d\u00edta\u010dov\u00fdch syst\u00e9m\u016f",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pvbps",
        "path_with_namespace": "Znoj-WEB/pvbps",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pvbps/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pvbps.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/pvbps",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738840/events",
            "issues": "https://gitlab.com/api/v4/projects/7738840/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738840/labels",
            "members": "https://gitlab.com/api/v4/projects/7738840/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738840/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738840/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738840"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738840/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:32:42.823Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP - Dashboard for different versions of tested framework. Should be used on tablet and phones where framework will be tested",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/profiq.git",
        "id": 7738840,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:46.587Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "profiq dashboard concept",
        "name_with_namespace": "Znoj-WEB / profiq dashboard concept",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "profiq",
        "path_with_namespace": "Znoj-WEB/profiq",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/profiq/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/profiq.git",
        "star_count": 0,
        "tag_list": [
            "2016",
            "HTML",
            "PHP",
            "profiq"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/profiq",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738827/events",
            "issues": "https://gitlab.com/api/v4/projects/7738827/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738827/labels",
            "members": "https://gitlab.com/api/v4/projects/7738827/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738827/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738827/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738827"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738827/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:31:05.214Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP - Web for school subject Programming I",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pr1.git",
        "id": 7738827,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:40.152Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Programov\u00e1n\u00ed 1",
        "name_with_namespace": "Znoj-WEB / Programov\u00e1n\u00ed 1",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pr1",
        "path_with_namespace": "Znoj-WEB/pr1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pr1/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pr1.git",
        "star_count": 0,
        "tag_list": [
            "2016",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/pr1",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738815/events",
            "issues": "https://gitlab.com/api/v4/projects/7738815/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738815/labels",
            "members": "https://gitlab.com/api/v4/projects/7738815/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738815/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738815/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738815"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738815/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:30:43.660Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP - Web for school subject Computer defence and attack",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pou.git",
        "id": 7738815,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:33.231Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Po\u010d\u00edta\u010dov\u00e1 obrana a \u00fatok",
        "name_with_namespace": "Znoj-WEB / Po\u010d\u00edta\u010dov\u00e1 obrana a \u00fatok",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pou",
        "path_with_namespace": "Znoj-WEB/pou",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pou/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pou.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/pou",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738796/events",
            "issues": "https://gitlab.com/api/v4/projects/7738796/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738796/labels",
            "members": "https://gitlab.com/api/v4/projects/7738796/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738796/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738796/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738796"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738796/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:28:20.275Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, CSS, PHP- Files and pages created during my school days for me or for other students created with Dominik Ther",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/fei8ucz.git",
        "id": 7738796,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:16.203Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "fei.8u.cz",
        "name_with_namespace": "Znoj-WEB / fei.8u.cz",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "fei8ucz",
        "path_with_namespace": "Znoj-WEB/fei8ucz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/fei8ucz/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/fei8ucz.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "CSS",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/fei8ucz",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738782/events",
            "issues": "https://gitlab.com/api/v4/projects/7738782/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738782/labels",
            "members": "https://gitlab.com/api/v4/projects/7738782/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738782/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738782/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738782"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738782/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:26:59.442Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML - Page for anniversary with hidden content - part of cipher I made",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pulrocniVyroci.git",
        "id": 7738782,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:53.800Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "V\u00fdro\u010d\u00ed",
        "name_with_namespace": "Znoj-WEB / V\u00fdro\u010d\u00ed",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "pulrocniVyroci",
        "path_with_namespace": "Znoj-WEB/pulrocniVyroci",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pulrocniVyroci/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pulrocniVyroci.git",
        "star_count": 0,
        "tag_list": [
            "2016",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/pulrocniVyroci",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738679/events",
            "issues": "https://gitlab.com/api/v4/projects/7738679/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738679/labels",
            "members": "https://gitlab.com/api/v4/projects/7738679/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738679/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738679/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738679"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738679/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:21:00.251Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, ExtJS - Simple download page created with ExtJS framework",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/ymca.git",
        "id": 7738679,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:16.049Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Download page YMCA",
        "name_with_namespace": "Znoj-WEB / Download page YMCA",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "ymca",
        "path_with_namespace": "Znoj-WEB/ymca",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/ymca/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/ymca.git",
        "star_count": 0,
        "tag_list": [
            "2017",
            "ExtJS"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/ymca",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738669/events",
            "issues": "https://gitlab.com/api/v4/projects/7738669/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738669/labels",
            "members": "https://gitlab.com/api/v4/projects/7738669/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738669/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738669/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738669"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738669/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:19:19.798Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, CSS, HTML, MySQL, PHP - Wall for notes",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zed.git",
        "id": 7738669,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:28.780Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Ze\u010f",
        "name_with_namespace": "Znoj-WEB / Ze\u010f",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "zed",
        "path_with_namespace": "Znoj-WEB/zed",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zed/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zed.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "CSS",
            "HTML",
            "MySQL",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/zed",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738650/events",
            "issues": "https://gitlab.com/api/v4/projects/7738650/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738650/labels",
            "members": "https://gitlab.com/api/v4/projects/7738650/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738650/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738650/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738650"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738650/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:15:33.881Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, CSS, HTML - Web created from photoshop designer",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/leasioCz.git",
        "id": 7738650,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:27.051Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Leasio",
        "name_with_namespace": "Znoj-WEB / Leasio",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "leasioCz",
        "path_with_namespace": "Znoj-WEB/leasioCz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/leasioCz/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/leasioCz.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "CSS",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/leasioCz",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738593/events",
            "issues": "https://gitlab.com/api/v4/projects/7738593/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738593/labels",
            "members": "https://gitlab.com/api/v4/projects/7738593/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738593/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738593/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738593"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738593/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:09:44.363Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, CSS, HTML - My movies library with links to trailers",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/KolousekFilmy.git",
        "id": 7738593,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:58.502Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Kolou\u0161ek Filmy",
        "name_with_namespace": "Znoj-WEB / Kolou\u0161ek Filmy",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "KolousekFilmy",
        "path_with_namespace": "Znoj-WEB/KolousekFilmy",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/KolousekFilmy/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/KolousekFilmy.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "CSS",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/KolousekFilmy",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738539/events",
            "issues": "https://gitlab.com/api/v4/projects/7738539/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738539/labels",
            "members": "https://gitlab.com/api/v4/projects/7738539/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738539/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738539/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738539"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738539/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T21:03:12.081Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Drupal - Current scout's web page - main informative medium",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junakBludov.git",
        "id": 7738539,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:49.041Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Skautsk\u00e9 str\u00e1nky 2013",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2013",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "junakBludov",
        "path_with_namespace": "Znoj-WEB/junakBludov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junakBludov/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junakBludov.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "Drupal"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/junakBludov",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738436/events",
            "issues": "https://gitlab.com/api/v4/projects/7738436/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738436/labels",
            "members": "https://gitlab.com/api/v4/projects/7738436/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738436/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738436/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738436"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738436/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:58:44.536Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, HTML - Web page for my friend's school project - outdoor game about Jan \u00c1mos Komensk\u00fd",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/JAKomensky.git",
        "id": 7738436,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:28.714Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "J. A. Komensk\u00fd",
        "name_with_namespace": "Znoj-WEB / J. A. Komensk\u00fd",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "JAKomensky",
        "path_with_namespace": "Znoj-WEB/JAKomensky",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/JAKomensky/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/JAKomensky.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/JAKomensky",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738146/events",
            "issues": "https://gitlab.com/api/v4/projects/7738146/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738146/labels",
            "members": "https://gitlab.com/api/v4/projects/7738146/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738146/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738146/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738146"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738146/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:51:31.314Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, HTML - Official web page of 2 veteran riders",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam.git",
        "id": 7738146,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:14.712Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Kol\u0161tejn Racing Team",
        "name_with_namespace": "Znoj-WEB / Kol\u0161tejn Racing Team",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "kolstejnRacingTeam",
        "path_with_namespace": "Znoj-WEB/kolstejnRacingTeam",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/kolstejnRacingTeam.git",
        "star_count": 0,
        "tag_list": [
            "2011",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738131/events",
            "issues": "https://gitlab.com/api/v4/projects/7738131/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738131/labels",
            "members": "https://gitlab.com/api/v4/projects/7738131/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738131/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738131/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738131"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738131/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:49:44.620Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2010, HTML - Web page for locksmithery",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zamecnictviStastny.git",
        "id": 7738131,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:22.381Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Z\u00e1me\u010dnictv\u00ed \u0160tastn\u00fd",
        "name_with_namespace": "Znoj-WEB / Z\u00e1me\u010dnictv\u00ed \u0160tastn\u00fd",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "zamecnictviStastny",
        "path_with_namespace": "Znoj-WEB/zamecnictviStastny",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zamecnictviStastny/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zamecnictviStastny.git",
        "star_count": 0,
        "tag_list": [
            "2010",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/zamecnictviStastny",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738097/events",
            "issues": "https://gitlab.com/api/v4/projects/7738097/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738097/labels",
            "members": "https://gitlab.com/api/v4/projects/7738097/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738097/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738097/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738097"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738097/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:46:28.674Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2010, HTML - Web page for horse riders",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/koneBludov.git",
        "id": 7738097,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:56:21.017Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Kon\u011b Bludov",
        "name_with_namespace": "Znoj-WEB / Kon\u011b Bludov",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "koneBludov",
        "path_with_namespace": "Znoj-WEB/koneBludov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/koneBludov/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/koneBludov.git",
        "star_count": 0,
        "tag_list": [
            "2010",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/koneBludov",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738081/events",
            "issues": "https://gitlab.com/api/v4/projects/7738081/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738081/labels",
            "members": "https://gitlab.com/api/v4/projects/7738081/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738081/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738081/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738081"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738081/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:43:50.102Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2009, HTML - Scout's web page used for 4 years",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junak2.git",
        "id": 7738081,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:41.583Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Skautsk\u00e9 str\u00e1nky 2009",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2009",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "junak2",
        "path_with_namespace": "Znoj-WEB/junak2",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junak2/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junak2.git",
        "star_count": 0,
        "tag_list": [
            "2009",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/junak2",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7738047/events",
            "issues": "https://gitlab.com/api/v4/projects/7738047/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738047/labels",
            "members": "https://gitlab.com/api/v4/projects/7738047/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738047/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738047/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738047"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738047/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T20:39:19.341Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, HTML - Fulfilled questions for ALG II",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/algOtazky.git",
        "id": 7738047,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:54:41.536Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Algoritmy II - ot\u00e1zky",
        "name_with_namespace": "Znoj-WEB / Algoritmy II - ot\u00e1zky",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "algOtazky",
        "path_with_namespace": "Znoj-WEB/algOtazky",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/algOtazky/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/algOtazky.git",
        "star_count": 0,
        "tag_list": [
            "2012",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/algOtazky",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737642/events",
            "issues": "https://gitlab.com/api/v4/projects/7737642/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737642/labels",
            "members": "https://gitlab.com/api/v4/projects/7737642/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737642/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737642/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737642"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737642/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:57:23.992Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - My first try for scout's web page",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junak1.git",
        "id": 7737642,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:35.040Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Skautsk\u00e9 str\u00e1nky 2005",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2005",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "junak1",
        "path_with_namespace": "Znoj-WEB/junak1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junak1/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junak1.git",
        "star_count": 0,
        "tag_list": [
            "2005",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/junak1",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737622/events",
            "issues": "https://gitlab.com/api/v4/projects/7737622/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737622/labels",
            "members": "https://gitlab.com/api/v4/projects/7737622/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737622/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737622/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737622"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737622/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:55:08.221Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - My first personal HTML page",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zs.git",
        "id": 7737622,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:57:41.458Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Z\u0160",
        "name_with_namespace": "Znoj-WEB / Z\u0160",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "zs",
        "path_with_namespace": "Znoj-WEB/zs",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zs/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zs.git",
        "star_count": 0,
        "tag_list": [
            "2005",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/zs",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737602/events",
            "issues": "https://gitlab.com/api/v4/projects/7737602/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737602/labels",
            "members": "https://gitlab.com/api/v4/projects/7737602/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737602/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737602/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737602"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737602/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:52:16.931Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - Simple HTML page for my sister created in elementary school",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/andulaHTML2005.git",
        "id": 7737602,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:54:54.452Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Andula HTML",
        "name_with_namespace": "Znoj-WEB / Andula HTML",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "andulaHTML2005",
        "path_with_namespace": "Znoj-WEB/andulaHTML2005",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/andulaHTML2005/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/andulaHTML2005.git",
        "star_count": 0,
        "tag_list": [
            "2005",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/andulaHTML2005",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737563/events",
            "issues": "https://gitlab.com/api/v4/projects/7737563/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737563/labels",
            "members": "https://gitlab.com/api/v4/projects/7737563/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737563/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737563/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737563"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737563/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:50:03.628Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, CSS, HTML - Layout of web page for Post\u0159elmov's parish",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/farnostPostrelmov.git",
        "id": 7737563,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:09.600Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Farnost Post\u0159elmov",
        "name_with_namespace": "Znoj-WEB / Farnost Post\u0159elmov",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "farnostPostrelmov",
        "path_with_namespace": "Znoj-WEB/farnostPostrelmov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/farnostPostrelmov/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/farnostPostrelmov.git",
        "star_count": 0,
        "tag_list": [
            "2014",
            "CSS",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/farnostPostrelmov",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737537/events",
            "issues": "https://gitlab.com/api/v4/projects/7737537/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737537/labels",
            "members": "https://gitlab.com/api/v4/projects/7737537/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737537/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737537/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737537"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737537/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:47:06.235Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, HTML - Page for my android game SOKOBAN",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/android_hra.git",
        "id": 7737537,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:54:48.137Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "Android - Sokoban",
        "name_with_namespace": "Znoj-WEB / Android - Sokoban",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "android_hra",
        "path_with_namespace": "Znoj-WEB/android_hra",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/android_hra/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/android_hra.git",
        "star_count": 0,
        "tag_list": [
            "2013",
            "HTML"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/android_hra",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737515/events",
            "issues": "https://gitlab.com/api/v4/projects/7737515/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737515/labels",
            "members": "https://gitlab.com/api/v4/projects/7737515/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737515/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737515/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737515"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737515/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:44:10.265Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, WordPress - Simple WordPress e-shop.",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/alda-knihy.info.git",
        "id": 7737515,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:54:35.144Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "alda-knihy.info",
        "name_with_namespace": "Znoj-WEB / alda-knihy.info",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "alda-knihy.info",
        "path_with_namespace": "Znoj-WEB/alda-knihy.info",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/alda-knihy.info/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/alda-knihy.info.git",
        "star_count": 0,
        "tag_list": [
            "2015",
            "WordPress"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/alda-knihy.info",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7737171/events",
            "issues": "https://gitlab.com/api/v4/projects/7737171/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737171/labels",
            "members": "https://gitlab.com/api/v4/projects/7737171/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737171/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737171/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737171"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737171/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-08-02T19:06:18.846Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, CSS, HTML, PHP - Web page for creating or viewing list of wishes - for Birthdays, Christmases, Weddings... Started with Dominik Ther",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/255.git",
        "id": 7737171,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:54:28.052Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "255 - Seznam p\u0159\u00e1n\u00ed",
        "name_with_namespace": "Znoj-WEB / 255 - Seznam p\u0159\u00e1n\u00ed",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "255",
        "path_with_namespace": "Znoj-WEB/255",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/255/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/255.git",
        "star_count": 0,
        "tag_list": [
            "2016",
            "CSS",
            "HTML",
            "PHP"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/255",
        "wiki_enabled": true
    },
    {
        "_links": {
            "events": "https://gitlab.com/api/v4/projects/7543238/events",
            "issues": "https://gitlab.com/api/v4/projects/7543238/issues",
            "labels": "https://gitlab.com/api/v4/projects/7543238/labels",
            "members": "https://gitlab.com/api/v4/projects/7543238/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7543238/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7543238/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7543238"
        },
        "archived": false,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7543238/icon.png",
        "ci_config_path": null,
        "container_registry_enabled": true,
        "created_at": "2018-07-18T21:10:01.000Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, JavaScript - React web app for countdown until chosen date and time in several units",
        "external_authorization_classification_label": "",
        "forks_count": 0,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/casReact.git",
        "id": 7543238,
        "import_status": "none",
        "issues_enabled": true,
        "jobs_enabled": true,
        "last_activity_at": "2019-02-11T21:55:00.861Z",
        "lfs_enabled": true,
        "merge_method": "merge",
        "merge_requests_enabled": true,
        "mirror": false,
        "name": "\u010cas",
        "name_with_namespace": "Znoj-WEB / \u010cas",
        "namespace": {
            "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "path": "casReact",
        "path_with_namespace": "Znoj-WEB/casReact",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/casReact/blob/master/README.md",
        "request_access_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_enabled": true,
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/casReact.git",
        "star_count": 1,
        "tag_list": [
            "2018",
            "JavaScript"
        ],
        "visibility": "private",
        "web_url": "https://gitlab.com/Znoj-WEB/casReact",
        "wiki_enabled": true
    }
]